import { API } from ".";
import { EVENT_NANES } from "../db/models/events";

// Visitor ID
let INITTriggered = false;
export const visitorINIT = async () => {
  if (INITTriggered) return null;
  INITTriggered = true;

  // API vid
  return API("events/vtr")
    .then(async (result) => {
      return result;
    })
    .catch((e) => {
      console.error(e);

      return null;
    });
};

export const newEvent = async (
  eventName: EVENT_NANES,
  data: { [key: string]: any } = {}
): Promise<boolean> => {
  return API(
    "events/new",
    {
      event: eventName,
      data: data,
    },
    "POST"
  );
};
