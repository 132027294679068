import { stripHtml } from "string-strip-html";
import { FETCH_METHODS } from "../types";

interface APIOptions {
  method: FETCH_METHODS;
  headers: { [key: string]: string };
  body?: string;
}

export const API = async (
  uri: string,
  data: { [key: string]: any } = {},
  method: FETCH_METHODS = "GET"
) => {
  const options: APIOptions = {
    method: method,
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (Object.keys(data)?.length > 0) options.body = JSON.stringify(data);

  return fetch(`/api/${uri}`, options).then(
    async (response) => await response.json()
  );
};

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const roundTo = (num: number, places: number): number => {
  const x = Math.pow(10, places);

  return Math.round(num * x) / x;
};

export const chunkArray = (arr: any[], chunk: number) => {
  let i: number;
  const j = arr.length,
    temparray = [];
  for (i = 0; i < j; i += chunk) temparray.push(arr.slice(i, i + chunk));

  return temparray;
};

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const ts = (): number => {
  return Math.floor(+new Date() / 1000);
};

export const cleanString = (str: string): string => {
  try {
    return stripHtml(str).result.trim().toString();
  } catch {}

  return "";
};

export const betweenDays = (currentDate: Date, daysDiff = 0): Date[] => {
  currentDate.setHours(0, 0, 0, 0);
  const betweens = [
    new Date(currentDate),
    new Date(currentDate.setDate(currentDate.getDate() + daysDiff)),
  ].sort((a, b) => (a.getTime() > b.getTime() ? 1 : -1));
  betweens[1].setHours(23, 59, 59, 999);

  return betweens;
};

export const addDays = (
  addNum: number,
  currentDate: Date = new Date()
): Date => {
  currentDate.setDate(currentDate.getDate() + addNum);

  return currentDate;
};

export const uniqueArray = (arrayTounique: any[]) =>
  arrayTounique.filter((value, index, array) => array.indexOf(value) === index);

export const changeTimeZone = (date: string | Date, timeZone: string): Date => {
  return new Date(
    new Date(date).toLocaleString("en-US", {
      timeZone,
    })
  );
};

export const alertColorToReactstrap = (color: string) => {
  switch (color) {
    case "success":
      return "success";
    case "error":
      return "danger";
    case "warning":
      return "warning";
    case "info":
      return "info";
    default:
      return "primary";
  }
}